@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Poppins:wght@400;500;600;700&family=Roboto:wght@300&display=swap');


body {
 margin: 0;
 font-family: 'Poppins', sans-serif;
 background: var(--bg);
 transition: all 3ms ease;
 color:var(--black);
 scroll-behavior: smooth;
}