.container {
  display: flex;
  padding: 1.5rem;
}

.logo {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 1rem;
}

.logo > img {
  width: 2.5rem;
  height: 2.5rem;
}
.logo > span {
  font-weight: 600;
}

.right {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.menu {
  display: flex;
  gap: 2rem;
  font-weight: 500;
  list-style: none;
}
.menu > li:hover {
  color: #fe956f;
  cursor: pointer;
}
.search {
    width: 7rem;
    height: 30%;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
}

.cart {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer; 
}

.bars {
  display: none;

}

@media screen and (max-width: 856px) {

  .right {
gap: 1rem; 
  }
  .search {
    display: none;
  }
}

@media screen and (max-width:640px) {
  .right {
    position:absolute;
    right: 2rem;
    z-index: 100;
    background-color: white;
    color: var(--black);
    border-radius: 5px;
    flex-direction: column;
    padding: 1rem;
  }
  .menu{
    flex-direction: column;
    margin-left: -2rem;
    display: none;
  }
  .bars {
    display: block;
    cursor: pointer;
  }
  .search {
    display: none;
  }
}